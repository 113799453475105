<template>
  <b-card no-body>
    <div class="m-2">
      <b-button
        variant="primary"
        @click="$router.push({ name: 'organization.venue.create' })"
      >
        Add Venue / Facility
      </b-button>
    </div>

    <!-- Venues List -->
    <table-list
      primary-key="id"
      show-empty
      :fields="fields"
      :items="venues"
      :endpoints="{ delete: '/venues' }"
      item-name="venue"
      @link-clicked="$router.push({ name: 'organization.venue.information', params: { venue: $event.id } })"
    />
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import TableList from '@/components/TableList.vue'
import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'
import store from '@/store'

const { flashError } = useFlash()

export default {
  components: {
    BCard,
    BButton,
    TableList,
  },
  data() {
    return {
      venues: [],
      fields: [
        'name',
        'abbreviation',
        {
          key: 'address',
          formatter: address => `${address.street_address} ${address.city}, ${address.state} ${address.postal_code}`,
        },
        {
          key: 'delete',
          label: '',
        },
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/organizations/${store.state.account.activeOrganization}/venues`)
      .then(response => {
        next(vm => {
          vm.venues = response.data.data
        })
      })
      .catch(error => {
        flashError(error)
        next({ name: 'organization.information' })
      })
  },
}
</script>

<style scoped>

</style>
